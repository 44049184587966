import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { useStores } from '../../../hooks';
import { Check, ChevronDown, ChevronUp, X } from 'react-feather';
import addUserIcon from '../assets/addUser.png';
import ConfirmModal from './confirmModal';
import { saveTokenPair } from '../../../apiCommandsExecutor/token';
import { TokenPair } from '../../../services/auth/models';
import { useNavigate } from 'react-router-dom';
import { AccountRole } from '../../../services/accounts/types';
import ChatStoreContext from '../../../common/components/chatModalComponent/store/context';

const AccountChangeModal = () => {
    const {
        authStore,
        generalStore,
        systemObjectsStore,
        organizationBranchesStore,
    } = useStores();
    const chatStore = useContext(ChatStoreContext);
    const open = authStore.accountChangeModal;
    const accounts = localStorage.getItem('accounts');
    const currentUser = authStore.currentUser;
    const [addUserState, setAddUserState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const close = () => {
        setName('');
        setPassword('');
        setAddUserState(false);
        authStore.closeAccountChangeModal();
    };

    const addAccount = async (e) => {
        setLoading(true);
        e.stopPropagation();
        await authStore.addUserToChangeModal({
            login: name,
            password: password,
        });
        if (!authStore.addUserAuthStatus) {
            generalStore.showSnackbar('Произошла ошибка. Проверьте данные.');
            setLoading(false);
        } else {
            chatStore.resetStore();
            organizationBranchesStore.resetData();
            systemObjectsStore.resetData();
            await authStore.getCurrentUser();
            await organizationBranchesStore
                .loadOrganizationBranches()
                .then(() => {
                    if (organizationBranchesStore.organizationBranches.length) {
                        systemObjectsStore
                            .getSystemObjectsByOrgBranchIds(
                                organizationBranchesStore
                                    .organizationBranches[0].id,
                            )
                            .then(() =>
                                generalStore.setOrgBranch(
                                    organizationBranchesStore
                                        .organizationBranches[0].id,
                                ),
                            );
                    } else {
                        return;
                    }
                });
            setLoading(false);
            if (authStore.isAuthorized && !loading) {
                if (
                    authStore.currentUser.role === AccountRole.Maintainer ||
                    authStore.currentUser.role === AccountRole.Admin ||
                    authStore.currentUser.role === AccountRole.Manager
                ) {
                    navigate(`/maintainer/pulse/branch/noBranchSelected`);
                } else if (authStore.currentUser.role === AccountRole.Support) {
                    navigate('/support/');
                } else if (
                    authStore.currentUser.role === AccountRole.Concierge
                ) {
                    navigate('/concierge/');
                } else if (
                    authStore.currentUser.role === AccountRole.SuperUser
                ) {
                    navigate('/super/smart-devices');
                } else if (
                    authStore.currentUser.role === AccountRole.TechnicalSupport
                ) {
                    navigate('/tech-support/chat-list');
                }
            }
            close();
            generalStore.showSnackbar(
                `Аккаунт ${name} добавлен в быстрый доступ`,
            );
        }
    };

    const changeAccount = async (token: TokenPair, login: string) => {
        setLoading(true);
        chatStore.resetStore();
        organizationBranchesStore.resetData();
        systemObjectsStore.resetData();
        await saveTokenPair(token);
        await authStore.getCurrentUser();
        await organizationBranchesStore.loadOrganizationBranches().then(() => {
            if (organizationBranchesStore.organizationBranches.length) {
                systemObjectsStore
                    .getSystemObjectsByOrgBranchIds(
                        organizationBranchesStore.organizationBranches[0].id,
                    )
                    .then(() =>
                        generalStore.setOrgBranch(
                            organizationBranchesStore.organizationBranches[0]
                                .id,
                        ),
                    );
            } else {
                return;
            }
        });
        await setLoading(false);
        if (authStore.isAuthorized && !loading) {
            if (
                authStore.currentUser.role === AccountRole.Maintainer ||
                authStore.currentUser.role === AccountRole.Admin ||
                authStore.currentUser.role === AccountRole.Manager
            ) {
                navigate(`/maintainer/pulse/branch/noBranchSelected`);
            } else if (authStore.currentUser.role === AccountRole.Support) {
                navigate('/support/');
            } else if (authStore.currentUser.role === AccountRole.Concierge) {
                navigate('/concierge');
            } else if (authStore.currentUser.role === AccountRole.SuperUser) {
                navigate('/super/smart-devices');
            } else if (
                authStore.currentUser.role === AccountRole.TechnicalSupport ||
                authStore.currentUser.role ===
                    AccountRole.TechnicalSupportManager
            ) {
                navigate('/tech-support/chat-list');
            }
        }
        close();
        generalStore.showSnackbar(`Вы переключились на аккаунт: ${login}`);
    };

    return (
        <Dialog open={open} maxWidth={'sm'} onClose={close} fullWidth>
            <DialogTitle>Смена аккаунта</DialogTitle>
            <DialogContent>
                {accounts &&
                    JSON.parse(accounts).map((i) => (
                        <div
                            key={i.login}
                            style={{
                                backgroundColor: '#FBFBFB',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: 15,
                                marginTop: 10,
                                borderRadius: 9,
                                alignItems: 'center',
                                border:
                                    currentUser.login === i.login
                                        ? '1px solid #2A73FF'
                                        : '1px solid #FBFBFB',
                                cursor:
                                    i.login !== authStore.currentUser.login &&
                                    'pointer',
                            }}
                            onClick={() =>
                                i.login !== authStore.currentUser.login &&
                                changeAccount(i.token, i.login)
                            }
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: 'Formular',
                                        color: '#1A1A1A',
                                        fontWeight: 700,
                                        fontSize: 16,
                                    }}
                                >
                                    {i.organization.data.name}
                                </span>
                                <span
                                    style={{
                                        fontFamily: 'Formular',
                                        color: '#1A1A1A',
                                        fontWeight: 400,
                                        fontSize: 14,
                                    }}
                                >
                                    {i.login}
                                </span>
                            </div>
                            {currentUser.login !== i.login ? (
                                <X
                                    style={{
                                        color: '#A9A9A9',
                                        width: 22,
                                        height: 22,
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        authStore.openConfirmModal({
                                            token: i.token,
                                            organization: i.organization,
                                            login: i.login,
                                        });
                                    }}
                                />
                            ) : (
                                <div
                                    style={{
                                        backgroundColor: '#2A73FF',
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '50%',
                                    }}
                                >
                                    <Check
                                        style={{
                                            width: 22,
                                            height: 22,
                                            color: '#FFF',
                                            padding: 2,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                <div
                    style={{
                        backgroundColor: '#FFFFFF',
                        padding: 15,
                        marginTop: 10,
                        borderRadius: 10,
                        border: '1px solid #BDBDBD',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setAddUserState(!addUserState);
                            !addUserState && setName('');
                            !addUserState && setPassword('');
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <img src={addUserIcon} width={25} height={29} />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: 10,
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: 'Formular',
                                        color: '#1A1A1A',
                                        fontWeight: 700,
                                        fontSize: 16,
                                    }}
                                >
                                    Добавить пользователя
                                </span>
                                <span
                                    style={{
                                        fontFamily: 'Formular',
                                        color: '#1A1A1A',
                                        fontWeight: 400,
                                        fontSize: 14,
                                    }}
                                >
                                    Вы сможете быстро переключаться между
                                    аккаунтами
                                </span>
                            </div>
                        </div>
                        {!addUserState ? (
                            <ChevronDown
                                style={{
                                    color: '#2A73FF',
                                    width: 26,
                                    height: 26,
                                }}
                            />
                        ) : (
                            <ChevronUp
                                style={{
                                    color: '#2A73FF',
                                    width: 26,
                                    height: 26,
                                }}
                            />
                        )}
                    </div>
                    {addUserState ? (
                        <div style={{ marginTop: 10 }}>
                            <TextField
                                value={name}
                                id='add-account-name'
                                variant='outlined'
                                style={{ marginTop: 10 }}
                                label={'Имя пользователя'}
                                type={'text'}
                                fullWidth
                                onChange={(e) => {
                                    e.stopPropagation();
                                    setName(e.target.value);
                                }}
                            />
                            <TextField
                                value={password}
                                id='add-account-password'
                                variant='outlined'
                                style={{ marginTop: 10 }}
                                label={'Пароль'}
                                type={'password'}
                                fullWidth
                                onChange={(e) => {
                                    e.stopPropagation();
                                    setPassword(e.target.value);
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                }}
                            >
                                <Button
                                    onClick={addAccount}
                                    variant={'contained'}
                                    color={'primary'}
                                    style={{
                                        marginTop: 10,
                                        width: 180,
                                        height: 40,
                                    }}
                                >
                                    {loading ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CircularProgress
                                                color={'inherit'}
                                                size={25}
                                            />
                                        </div>
                                    ) : (
                                        'Добавить аккаунт'
                                    )}
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </div>
                <ConfirmModal />
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'primary'}>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(AccountChangeModal);
