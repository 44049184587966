import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
} from '@mui/material';
import { ChevronLeft, ChevronRight, X } from 'react-feather';
import FullScreenModalStoreContext from './store/context';
import { FileTypes } from '../../../services/objectStorage/libs';
import { isHeic } from '../../utils/attachmentsViewUtils';
import HeicConverter from '../HeicConverter/heicConverter';

const FullScreenModal = () => {
    const fullScreenModalStore = useContext(FullScreenModalStoreContext);
    const open = fullScreenModalStore.isOpen;
    const [prevButton, setPrevButton] = useState(true);
    const [nextButton, setNextButton] = useState(true);

    const nextAttachment = () => {
        const index = fullScreenModalStore.currentAttachments.findIndex(
            (i) =>
                i.downloadLink === fullScreenModalStore.currentAttachmentLink,
        );
        const nextElement = fullScreenModalStore.currentAttachments[index + 1];
        if (nextElement.filename) {
            const split = nextElement.filename.toLowerCase().split('.');
            const formatName = split[split.length - 1];
            switch (FileTypes[formatName]) {
                case 'image':
                    fullScreenModalStore.setFormatAttachment('image');
                    break;
                case 'video':
                    fullScreenModalStore.setFormatAttachment('video');
                    break;
            }
            fullScreenModalStore.setCurrentAttachmentLinkAndName(
                nextElement.downloadLink,
                nextElement.filename,
            );
        }
        if (nextElement.fileName) {
            const split = nextElement.fileName.toLowerCase().split('.');
            const formatName = split[split.length - 1];
            switch (FileTypes[formatName]) {
                case 'image':
                    fullScreenModalStore.setFormatAttachment('image');
                    break;
                case 'video':
                    fullScreenModalStore.setFormatAttachment('video');
                    break;
            }
            fullScreenModalStore.setCurrentAttachmentLinkAndName(
                nextElement.downloadLink,
                nextElement.fileName,
            );
        }
    };

    const prevAttachment = () => {
        const index = fullScreenModalStore.currentAttachments.findIndex(
            (i) =>
                i.downloadLink === fullScreenModalStore.currentAttachmentLink,
        );
        const prevElement = fullScreenModalStore.currentAttachments[index - 1];
        if (prevElement.filename) {
            const split = prevElement.filename.toLowerCase().split('.');
            const formatName = split[split.length - 1];
            switch (FileTypes[formatName]) {
                case 'image':
                    fullScreenModalStore.setFormatAttachment('image');
                    break;
                case 'video':
                    fullScreenModalStore.setFormatAttachment('video');
                    break;
            }
            fullScreenModalStore.setCurrentAttachmentLinkAndName(
                prevElement.downloadLink,
                prevElement.filename,
            );
        }
        if (prevElement.fileName) {
            const split = prevElement.fileName.toLowerCase().split('.');
            const formatName = split[split.length - 1];
            switch (FileTypes[formatName]) {
                case 'image':
                    fullScreenModalStore.setFormatAttachment('image');
                    break;
                case 'video':
                    fullScreenModalStore.setFormatAttachment('video');
                    break;
            }
            fullScreenModalStore.setCurrentAttachmentLinkAndName(
                prevElement.downloadLink,
                prevElement.fileName,
            );
        }
    };

    useEffect(() => {
        if (!fullScreenModalStore.currentAttachmentLink) return;
        setNextButton(true);
        setPrevButton(true);
        const index = fullScreenModalStore.currentAttachments.findIndex(
            (i) =>
                i.downloadLink === fullScreenModalStore.currentAttachmentLink,
        );
        if (index === 0) {
            setPrevButton(false);
        }
        if (index === fullScreenModalStore.currentAttachments.length - 1) {
            setNextButton(false);
        }
    }, [
        fullScreenModalStore.currentAttachmentLink,
        fullScreenModalStore.currentAttachments,
    ]);

    return (
        <Dialog open={open} fullWidth={false} maxWidth={false}>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <IconButton
                    onClick={() => {
                        setNextButton(true);
                        setPrevButton(true);
                        fullScreenModalStore.closeFullScreenModal();
                    }}
                >
                    <X color={'gray'} />
                </IconButton>
            </div>
            <DialogContent>
                {fullScreenModalStore.formatAttachment === 'image' &&
                fullScreenModalStore.currentAttachmentName ? (
                    isHeic(fullScreenModalStore.currentAttachmentName) ? (
                        <HeicConverter
                            url={fullScreenModalStore.currentAttachmentLink}
                            name={fullScreenModalStore.currentAttachmentName}
                            attachments={
                                fullScreenModalStore.currentAttachments
                            }
                            style={{
                                width: '100%',
                                height: '100%',
                                maxHeight: 650,
                                objectFit: 'contain',
                            }}
                        />
                    ) : (
                        <img
                            src={fullScreenModalStore.currentAttachmentLink}
                            style={{
                                width: '100%',
                                height: '100%',
                                maxHeight: 650,
                                objectFit: 'contain',
                            }}
                        />
                    )
                ) : null}
                {fullScreenModalStore.formatAttachment === 'video' ? (
                    <video
                        src={fullScreenModalStore.currentAttachmentLink}
                        autoPlay={true}
                        muted={false}
                        controls={true}
                        style={{
                            width: '100%',
                            height: '100%',
                            maxHeight: 650,
                            objectFit: 'cover',
                        }}
                    />
                ) : null}
            </DialogContent>
            <DialogActions>
                <IconButton
                    onClick={prevAttachment}
                    disabled={!prevButton}
                    color={'primary'}
                >
                    <ChevronLeft />
                </IconButton>
                <IconButton
                    onClick={nextAttachment}
                    disabled={!nextButton}
                    color={'primary'}
                >
                    <ChevronRight />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

export default observer(FullScreenModal);
