export enum AccountRole {
    Maintainer = 'Maintainer',
    Support = 'Support',
    Manager = 'Manager',
    Service = 'Service',
    Admin = 'Admin',
    HouseResident = 'HouseResident',
    Integration = 'Integration',
    Concierge = 'Concierge',
    SuperUser = 'SuperUser',
    TechnicalSupport = 'TechnicalSupport',
    TechnicalSupportManager = 'TechnicalSupportManager',
}

export type AccountMainPermissionProperty =
    | 'VISIBLE_ORG_BRANCH_IDS'
    | 'CAMERA_IDS';
