import React, { useContext, useEffect, useRef, useState } from 'react';
import ChatStoreContext from './store/context';
import { observer } from 'mobx-react';
import {
    DialogActions,
    DialogContent,
    IconButton,
    TextField,
} from '@mui/material';
import { ArrowUp, Paperclip, X } from 'react-feather';
import Loader from '../Loader';
import ChatMessageItem from './chatMessageItem';
import {
    getDownloadLink,
    getUploadLink,
} from '../../../services/objectStorage';
import axios from 'axios';
import FullScreenModal from '../fullScreenModalComponent/fullScreenModal';
import ChatListStoreContext from '../../../modules/maintainer/pages/ChatList/store/context';
import { useStores } from '../../../hooks';
import SupportChatListStoreContext from '../../../modules/techSupport/store/context';
import pdfIcon from '../../../assets/docs/pdf.png';
import docxIcon from '../../../assets/docs/word.png';
import xlsxIcon from '../../../assets/docs/excel.png';
import ppIcon from '../../../assets/docs/pptx.png';
import { FileTypes } from '../../../services/objectStorage/libs';
import { isHeic } from '../../utils/attachmentsViewUtils';
import HeicConverter from '../HeicConverter/heicConverter';
import { PostAdd } from '@mui/icons-material';
import CRMPageStoreContext from '../../../modules/maintainer/pages/crm/store/context';

interface chatDataProps {
    chatHeight: string;
    chatPlace: string;
}

const ChatData = (props: chatDataProps) => {
    const { generalStore } = useStores();
    const chatListStore = useContext(ChatListStoreContext);
    const supportChatListStore = useContext(SupportChatListStoreContext);
    const chatStore = useContext(ChatStoreContext);
    const CRMPageStore = useContext(CRMPageStoreContext);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const contentRef = useRef(null);
    const inputRef = useRef(null);
    const textRef = useRef(null);
    const docFormats = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
    const filterAttachments =
        chatStore.links.length &&
        chatStore.links.filter((i) => {
            const filterSplit = i.fileName
                ? i.fileName.toLowerCase().split('.')
                : i.fileName.toLowerCase().split('.');
            const filterName = filterSplit[filterSplit.length - 1];
            return !docFormats.includes(filterName);
        });

    const uploadFile = async (file: File, url: string) => {
        await axios.request({
            method: 'PUT',
            url: url,
            data: file,
        });
    };

    const sendMessage = async () => {
        const clean = message
            .split(' ')
            .filter((i) => i !== '')
            .join(' ');
        const trimMsg = clean.trim();
        if (trimMsg === '' && !chatStore.attachments.length) return;
        await chatStore.addMessageToChat(trimMsg);
        setMessage('');
        await chatStore.updateChatMessages(chatStore.chatId);
        props.chatPlace === 'flatList' &&
            (await chatListStore.getFlatChatList(
                0,
                generalStore.currentOrganizationBranch.id,
            ));
        props.chatPlace === 'techSupList' &&
            (await supportChatListStore.getSupportChatList(0));
        chatStore.setAttachments([]);
        chatStore.setLinks([]);
    };

    const inputField = document.getElementById('newMessage');

    useEffect(() => {
        if (!chatStore.chatId) return;
        setLoading(true);
        setMessage('');
        chatStore.setAttachments([]);
        chatStore.setLinks([]);
        chatStore
            .getChatMessages(chatStore.chatId)
            .then(() => setLoading(false));
        if (props.chatPlace !== 'modal') {
            inputField?.focus();
        }
        const interval = setInterval(async () => {
            !chatStore.chatId && clearInterval(interval);
            !loading && (await chatStore.updateChatMessages(chatStore.chatId));
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [chatStore.chatId]);

    const insertNewLine = () => {
        const textArea = textRef.current.querySelector('textarea');
        const startPos = textArea.selectionStart;
        textArea.value =
            textArea.value.slice(0, startPos) +
            '\n' +
            textArea.value.slice(startPos);
        setMessage(textArea.value);
        textArea.selectionStart = startPos + 1;
        textArea.selectionEnd = startPos + 1;
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!contentRef.current) return;
            contentRef.current.scrollIntoView({
                block: 'end',
            });
            !chatStore.chatId && clearTimeout(timeout);
        }, 1000);
    }, [chatStore.chatMessages]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: props.chatHeight,
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <FullScreenModal />
            <div
                style={{
                    display: 'flex',
                    justifyContent: chatStore.isOpen
                        ? 'space-between'
                        : 'center',
                    alignItems: 'center',
                    padding: chatStore.chatTitle ? 12 : 22,
                    backgroundColor: '#fff',
                    width: '100%',
                    position: 'sticky',
                    zIndex: 1,
                    top: 0,
                }}
            >
                <span
                    style={{
                        fontWeight: 500,
                        fontSize: 14,
                        fontFamily: 'Formular',
                    }}
                >
                    {chatStore.chatId ? chatStore.chatTitle : ''}
                </span>
                {chatStore.isOpen ? (
                    <IconButton
                        onClick={async () => {
                            await chatStore.readChatMessages();
                            await chatStore.closeModal();
                        }}
                        style={{ padding: 3 }}
                    >
                        <X color={'gray'} width={15} height={15} />
                    </IconButton>
                ) : null}
            </div>
            <DialogContent
                style={{
                    backgroundColor: '#ECF3FF',
                    flex: 1,
                    overflowY: 'auto',
                    boxSizing: 'border-box',
                    padding: '8px 4px 0 4px',
                    display:
                        (chatStore.chatMessages &&
                            chatStore.chatMessages.length === 0 &&
                            'flex') ||
                        undefined,
                    alignItems:
                        (chatStore.chatMessages &&
                            chatStore.chatMessages.length === 0 &&
                            'center') ||
                        undefined,
                    justifyContent:
                        (chatStore.chatMessages &&
                            chatStore.chatMessages.length === 0 &&
                            'center') ||
                        undefined,
                }}
            >
                <div
                    ref={contentRef}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        paddingBottom: 10,
                    }}
                >
                    {chatStore.chatId ? (
                        loading ? (
                            <Loader />
                        ) : (
                            <>
                                {chatStore.chatMessages &&
                                chatStore.chatMessages.length === 0 ? (
                                    <div>
                                        <span style={{ color: 'gray' }}>
                                            История ваших сообщений пуста
                                        </span>
                                    </div>
                                ) : (
                                    <div>
                                        {chatStore.chatMessages.map(
                                            (chatMessage, index) => (
                                                <ChatMessageItem
                                                    item={chatMessage}
                                                    key={index}
                                                />
                                            ),
                                        )}
                                    </div>
                                )}
                            </>
                        )
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                                overflow: 'auto',
                                boxSizing: 'border-box',
                            }}
                        >
                            <span style={{ color: 'gray' }}>
                                Выберите, кому хотели бы написать
                            </span>
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: '#fff',
                    paddingBottom: 0,
                }}
            >
                <input
                    accept='image/*, video/*, application/pdf, .docx, .doc, .xlsx, .xls, .ppt, .pptx'
                    id='icon-button-file'
                    type='file'
                    style={{ display: 'none' }}
                    ref={inputRef}
                    multiple={true}
                    disabled={!chatStore.chatId}
                    onChange={async (event) => {
                        if (event.target.files.length > 0) {
                            const currentFiles = event.target.files;
                            if (currentFiles.length > 0) {
                                Object.values(currentFiles).map(
                                    async (item) => {
                                        const response = await getUploadLink({
                                            filename: item.name,
                                            isPublic: false,
                                            accountsIdsWithAccess: [],
                                        });
                                        if (!response) {
                                            return;
                                        }
                                        const attachment = {
                                            name: item.name,
                                            fileId: response.fileId,
                                        };
                                        chatStore.attachments.push(attachment);
                                        await uploadFile(
                                            item,
                                            response.uploadLink,
                                        );
                                        await getDownloadLink({
                                            fileId: response.fileId,
                                        }).then((res) =>
                                            chatStore.links.push({
                                                link: res.downloadLink,
                                                fileName: item.name,
                                                fileId: response.fileId,
                                            }),
                                        );
                                    },
                                );
                            }
                        }
                        inputField.focus();
                    }}
                />
                <label htmlFor='icon-button-file' color='primary'>
                    <IconButton
                        color={'primary'}
                        component='span'
                        style={{ marginBottom: 5 }}
                        size={'small'}
                        title={'Прикрепить вложения'}
                    >
                        <div
                            style={{
                                backgroundColor: '#fff',
                                padding: 2,
                                borderRadius: '50%',
                            }}
                        >
                            <Paperclip style={{ margin: '5px 5px 0 5px' }} />
                        </div>
                    </IconButton>
                </label>
                <TextField
                    value={message}
                    ref={textRef}
                    id='newMessage'
                    size={'small'}
                    variant='outlined'
                    style={{ marginBottom: 5 }}
                    type={'text'}
                    fullWidth
                    multiline
                    placeholder={'Введите ваше сообщение...'}
                    disabled={!chatStore.chatId}
                    autoFocus={chatStore.chatId && true}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={async (e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            await sendMessage();
                        }
                        if (e.shiftKey && e.key === 'Enter') {
                            e.preventDefault();
                            insertNewLine();
                        }
                    }}
                />
                <IconButton
                    style={{ marginBottom: 5 }}
                    size={'small'}
                    disabled={!chatStore.chatId}
                    onClick={sendMessage}
                    title={'Отправить'}
                >
                    <div
                        style={{
                            backgroundColor: '#3776F0',
                            padding: 2,
                            borderRadius: '50%',
                        }}
                    >
                        <ArrowUp
                            style={{ margin: '5px 5px 0 5px' }}
                            color={'#fff'}
                        />
                    </div>
                </IconButton>
                {chatStore.chatId && props.chatPlace === 'techSupList' && (
                    <IconButton
                        style={{ marginBottom: 5 }}
                        size={'small'}
                        disabled={!chatStore.chatId}
                        onClick={() => {
                            CRMPageStore.setCurrentFlatOwnerInfo(undefined);
                            CRMPageStore.setCreateNewRequestModal(true);
                        }}
                        title={'Создать заявку'}
                    >
                        <div
                            style={{
                                backgroundColor: '#fff',
                                padding: 2,
                                borderRadius: '50%',
                            }}
                        >
                            <PostAdd
                                style={{
                                    margin: '5px 5px 0 5px',
                                    color: '#3776F0',
                                }}
                            />
                        </div>
                    </IconButton>
                )}
            </DialogActions>
            {chatStore.chatId
                ? chatStore.attachments.length > 0 && (
                      <div
                          style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              padding: '0px 10px',
                              marginBottom: 10,
                          }}
                      >
                          <div
                              style={{
                                  display: 'flex',
                                  marginLeft: 60,
                                  flexWrap: 'wrap',
                                  width: '80%',
                              }}
                          >
                              {chatStore.attachments
                                  .slice()
                                  .sort((a) => {
                                      if (
                                          a.name.includes('docx') ||
                                          a.name.includes('doc') ||
                                          a.name.includes('xlsx') ||
                                          a.name.includes('xls') ||
                                          a.name.includes('pdf') ||
                                          a.name.includes('ppt') ||
                                          a.name.includes('pptx')
                                      ) {
                                          return -1;
                                      } else return 1;
                                  })
                                  .map((item, index) => {
                                      const filter = chatStore.links.filter(
                                          (i) => i.fileId === item.fileId,
                                      );
                                      if (filter.length) {
                                          const split = filter[0].fileName
                                              .toLowerCase()
                                              .split('.');
                                          const formatName =
                                              split[split.length - 1];
                                          switch (FileTypes[formatName]) {
                                              case 'pdf':
                                                  return (
                                                      <div
                                                          key={index}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:
                                                                  'center',
                                                              justifyContent:
                                                                  'space-between',
                                                              width: '100%',
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  display:
                                                                      'flex',
                                                                  width: '90%',
                                                                  alignItems:
                                                                      'center',
                                                                  overflow:
                                                                      'hidden',
                                                              }}
                                                          >
                                                              <img
                                                                  src={pdfIcon}
                                                                  style={{
                                                                      maxWidth: 30,
                                                                      maxHeight: 30,
                                                                  }}
                                                              />
                                                              <span
                                                                  style={{
                                                                      marginLeft: 5,
                                                                      overflow:
                                                                          'hidden',
                                                                      textOverflow:
                                                                          'ellipsis',
                                                                      whiteSpace:
                                                                          'nowrap',
                                                                  }}
                                                              >
                                                                  {
                                                                      filter[0]
                                                                          .fileName
                                                                  }
                                                              </span>
                                                          </div>
                                                          <X
                                                              width={15}
                                                              height={15}
                                                              style={{
                                                                  cursor: 'pointer',
                                                              }}
                                                              onClick={() => {
                                                                  chatStore.removeLink(
                                                                      filter[0]
                                                                          .fileName,
                                                                  );
                                                                  chatStore.removeAttachment(
                                                                      filter[0]
                                                                          .fileName,
                                                                  );
                                                              }}
                                                          />
                                                      </div>
                                                  );
                                              case 'pp':
                                                  return (
                                                      <div
                                                          key={index}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:
                                                                  'center',
                                                              justifyContent:
                                                                  'space-between',
                                                              width: '100%',
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  display:
                                                                      'flex',
                                                                  width: '90%',
                                                                  alignItems:
                                                                      'center',
                                                                  overflow:
                                                                      'hidden',
                                                              }}
                                                          >
                                                              <img
                                                                  src={ppIcon}
                                                                  style={{
                                                                      maxWidth: 30,
                                                                      maxHeight: 30,
                                                                  }}
                                                              />
                                                              <span
                                                                  style={{
                                                                      marginLeft: 5,
                                                                      overflow:
                                                                          'hidden',
                                                                      textOverflow:
                                                                          'ellipsis',
                                                                      whiteSpace:
                                                                          'nowrap',
                                                                  }}
                                                              >
                                                                  {
                                                                      filter[0]
                                                                          .fileName
                                                                  }
                                                              </span>
                                                          </div>
                                                          <X
                                                              width={15}
                                                              height={15}
                                                              style={{
                                                                  cursor: 'pointer',
                                                              }}
                                                              onClick={() => {
                                                                  chatStore.removeLink(
                                                                      filter[0]
                                                                          .fileName,
                                                                  );
                                                                  chatStore.removeAttachment(
                                                                      filter[0]
                                                                          .fileName,
                                                                  );
                                                              }}
                                                          />
                                                      </div>
                                                  );
                                              case 'word':
                                                  return (
                                                      <div
                                                          key={index}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:
                                                                  'center',
                                                              justifyContent:
                                                                  'space-between',
                                                              width: '100%',
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  display:
                                                                      'flex',
                                                                  width: '90%',
                                                                  alignItems:
                                                                      'center',
                                                                  overflow:
                                                                      'hidden',
                                                              }}
                                                          >
                                                              <img
                                                                  src={docxIcon}
                                                                  style={{
                                                                      maxWidth: 30,
                                                                      maxHeight: 30,
                                                                  }}
                                                              />
                                                              <span
                                                                  style={{
                                                                      marginLeft: 5,
                                                                      overflow:
                                                                          'hidden',
                                                                      textOverflow:
                                                                          'ellipsis',
                                                                      whiteSpace:
                                                                          'nowrap',
                                                                  }}
                                                              >
                                                                  {
                                                                      filter[0]
                                                                          .fileName
                                                                  }
                                                              </span>
                                                          </div>
                                                          <X
                                                              width={15}
                                                              height={15}
                                                              style={{
                                                                  cursor: 'pointer',
                                                              }}
                                                              onClick={() => {
                                                                  chatStore.removeLink(
                                                                      filter[0]
                                                                          .fileName,
                                                                  );
                                                                  chatStore.removeAttachment(
                                                                      filter[0]
                                                                          .fileName,
                                                                  );
                                                              }}
                                                          />
                                                      </div>
                                                  );
                                              case 'excel':
                                                  return (
                                                      <div
                                                          key={index}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:
                                                                  'center',
                                                              justifyContent:
                                                                  'space-between',
                                                              width: '100%',
                                                          }}
                                                      >
                                                          <div
                                                              style={{
                                                                  display:
                                                                      'flex',
                                                                  width: '90%',
                                                                  alignItems:
                                                                      'center',
                                                                  overflow:
                                                                      'hidden',
                                                              }}
                                                          >
                                                              <img
                                                                  src={xlsxIcon}
                                                                  style={{
                                                                      maxWidth: 30,
                                                                      maxHeight: 30,
                                                                  }}
                                                              />
                                                              <span
                                                                  style={{
                                                                      marginLeft: 5,
                                                                      overflow:
                                                                          'hidden',
                                                                      textOverflow:
                                                                          'ellipsis',
                                                                      whiteSpace:
                                                                          'nowrap',
                                                                  }}
                                                              >
                                                                  {
                                                                      filter[0]
                                                                          .fileName
                                                                  }
                                                              </span>
                                                          </div>
                                                          <X
                                                              width={15}
                                                              height={15}
                                                              style={{
                                                                  cursor: 'pointer',
                                                              }}
                                                              onClick={() => {
                                                                  chatStore.removeLink(
                                                                      filter[0]
                                                                          .fileName,
                                                                  );
                                                                  chatStore.removeAttachment(
                                                                      filter[0]
                                                                          .fileName,
                                                                  );
                                                              }}
                                                          />
                                                      </div>
                                                  );
                                              case 'image':
                                                  if (
                                                      isHeic(filter[0].fileName)
                                                  ) {
                                                      return (
                                                          <HeicConverter
                                                              url={
                                                                  filter[0].link
                                                              }
                                                              name={
                                                                  filter[0]
                                                                      .fileName
                                                              }
                                                              attachments={
                                                                  filterAttachments
                                                              }
                                                              style={{
                                                                  maxWidth: 120,
                                                                  maxHeight: 80,
                                                                  borderRadius: 5,
                                                                  marginRight: 5,
                                                              }}
                                                          />
                                                      );
                                                  } else {
                                                      return (
                                                          <div
                                                              key={index}
                                                              style={{
                                                                  position:
                                                                      'relative',
                                                              }}
                                                          >
                                                              <img
                                                                  src={
                                                                      filter[0]
                                                                          .link
                                                                  }
                                                                  style={{
                                                                      maxWidth: 120,
                                                                      maxHeight: 80,
                                                                      borderRadius: 5,
                                                                      marginRight: 5,
                                                                  }}
                                                              />
                                                              <IconButton
                                                                  style={{
                                                                      position:
                                                                          'absolute',
                                                                      top: 0,
                                                                      right: 0,
                                                                      padding:
                                                                          '1px 5px',
                                                                      marginRight: 3,
                                                                      marginTop: 3,
                                                                  }}
                                                                  size={'small'}
                                                                  onClick={() => {
                                                                      chatStore.removeLink(
                                                                          filter[0]
                                                                              .fileName,
                                                                      );
                                                                      chatStore.removeAttachment(
                                                                          filter[0]
                                                                              .fileName,
                                                                      );
                                                                  }}
                                                              >
                                                                  <X
                                                                      width={15}
                                                                      height={
                                                                          15
                                                                      }
                                                                  />
                                                              </IconButton>
                                                          </div>
                                                      );
                                                  }
                                              case 'video':
                                                  return (
                                                      <div
                                                          key={index}
                                                          style={{
                                                              position:
                                                                  'relative',
                                                          }}
                                                      >
                                                          <video
                                                              src={
                                                                  filter[0].link
                                                              }
                                                              style={{
                                                                  maxWidth: 120,
                                                                  maxHeight: 80,
                                                                  borderRadius: 5,
                                                                  marginRight: 5,
                                                              }}
                                                              autoPlay={false}
                                                              muted={true}
                                                          />
                                                          <IconButton
                                                              style={{
                                                                  position:
                                                                      'absolute',
                                                                  top: 0,
                                                                  right: 0,
                                                                  padding:
                                                                      '1px 5px',
                                                                  marginRight: 3,
                                                                  marginTop: 3,
                                                              }}
                                                              size={'small'}
                                                              onClick={() => {
                                                                  chatStore.removeLink(
                                                                      filter[0]
                                                                          .fileName,
                                                                  );
                                                                  chatStore.removeAttachment(
                                                                      filter[0]
                                                                          .fileName,
                                                                  );
                                                              }}
                                                          >
                                                              <X
                                                                  width={15}
                                                                  height={15}
                                                              />
                                                          </IconButton>
                                                      </div>
                                                  );
                                          }
                                      } else {
                                          return (
                                              <div
                                                  key={index}
                                                  style={{
                                                      position: 'relative',
                                                  }}
                                              >
                                                  <div
                                                      style={{
                                                          width: 120,
                                                          height: 80,
                                                          borderRadius: 5,
                                                          marginRight: 5,
                                                          backgroundColor:
                                                              '#F1F1F1',
                                                      }}
                                                  >
                                                      <Loader />
                                                  </div>
                                                  <IconButton
                                                      style={{
                                                          position: 'absolute',
                                                          top: 0,
                                                          right: 0,
                                                          padding: '1px 5px',
                                                          marginRight: 3,
                                                          marginTop: 3,
                                                      }}
                                                      size={'small'}
                                                      onClick={() => {
                                                          chatStore.removeAttachment(
                                                              item.name,
                                                          );
                                                      }}
                                                  >
                                                      <X
                                                          width={15}
                                                          height={15}
                                                      />
                                                  </IconButton>
                                              </div>
                                          );
                                      }
                                  })}
                          </div>
                      </div>
                  )
                : null}
        </div>
    );
};

export default observer(ChatData);
