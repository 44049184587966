import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { getSupportChatList } from '../../../services/chat';
import {
    FlatInfo,
    ShortAccountModel,
    TechSupportChatLastMessageClient,
} from '../../../services/chat/models';
import { SystemObjectType } from '../../../services/systemObjects/types';
import { getSystemObjects } from '../../../services/systemObjects';
import { SystemObject } from '../../../services/systemObjects/models';

export class SupportChatListStore {
    searchCount: string = '';
    supportChatList: TechSupportChatLastMessageClient[] = [];
    userData: ShortAccountModel;
    userFlats: FlatInfo[] = [];
    housesInRequestOrgBranch: SystemObject[] = [];
    flatsByHouseInRequestOrgBranch: { [houseId: number]: SystemObject[] } = {};

    constructor() {
        makeAutoObservable(this);
    }

    async getSupportChatList(skip: number) {
        const response = await getSupportChatList({
            limit: 120,
            skip: skip,
        });
        runInAction(() => {
            this.supportChatList = response.flatChatLastMessages;
        });
    }

    async getHousesWithFlatsForRequest() {
        const res = await getSystemObjects({
            types: [SystemObjectType.House, SystemObjectType.Flat],
            organizationBranchesIds: [],
        });
        runInAction(() => {
            this.housesInRequestOrgBranch =
                res?.systemObjectsByParentId['null'];
            delete res?.systemObjectsByParentId['null'];
            this.flatsByHouseInRequestOrgBranch =
                res?.systemObjectsByParentId || {};
        });
    }

    @action
    setUserData(account: ShortAccountModel) {
        this.userData = account;
    }

    @action
    setUserFlats(flats: FlatInfo[]) {
        this.userFlats = flats;
    }

    @action
    cleanStore() {
        this.userFlats = [];
        this.userData = undefined;
    }

    @action
    setSearchCount(text: string) {
        this.searchCount = text;
    }

    @computed
    flatChatLastMessagesFilterByCount() {
        if (this.searchCount.length === 0) {
            return this.supportChatList;
        } else {
            return this.supportChatList.filter((i) =>
                i.chat.title.includes(this.searchCount),
            );
        }
    }
}

const supportChatListStore = new SupportChatListStore();

export default supportChatListStore;
