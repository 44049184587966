import { execute, generateClientSyncId } from '../../apiCommandsExecutor';
import {
    AccountsCreateAccountRequest,
    AccountsCreateAccountResponse,
    AccountsGetAccountsRequest,
    AccountsGetAccountsResponse,
    AccountPermissionsResponse,
    AccountPermissionsUpdatePermissionRequest,
    AccountsGetMeResponse,
    AccountsRemoveAccountRequest,
    AccountsRemoveAccountResponse,
    AccountsUpdateAccountRequest,
    AccountsUpdateAccountResponse,
    AccountsGetEmployeeAccountsRequest,
    AccountsGetEmployeeAccountsResponse,
    AccountsGetTechnicalSupportAccountsRequest, AccountsGetTechnicalSupportAccountsResponse,
} from './requestsResponses';
import { AccountsAPI } from './methods';
import { ICreateAccountParams, IUpdateAccountParams } from './models';
import { AccountRole } from './types';

export const getCurrent = async () => {
    return await execute<{}, AccountsGetMeResponse>(AccountsAPI.AccountsGetMe, {
        clientSyncId: generateClientSyncId(),
    });
};

export const createAccount = async (params: ICreateAccountParams) => {
    const res = await execute<
        AccountsCreateAccountRequest,
        AccountsCreateAccountResponse
    >(AccountsAPI.AccountsCreateAccount, {
        ...params,
    });
    return res.data;
};

export const updateAccount = async (params: IUpdateAccountParams) => {
    const res = await execute<
        AccountsUpdateAccountRequest,
        AccountsUpdateAccountResponse
    >(AccountsAPI.AccountsUpdateAccount, {
        ...params,
    });
    return res.data;
};

export const removeAccount = async (params: { accountId: number }) => {
    const res = await execute<
        AccountsRemoveAccountRequest,
        AccountsRemoveAccountResponse
    >(AccountsAPI.AccountsRemoveAccount, {
        ...params,
    });
    return res.data;
};

export const getAccounts = async (params: { accountRoles: AccountRole[] }) => {
    const res = await execute<
        AccountsGetAccountsRequest,
        AccountsGetAccountsResponse
    >(AccountsAPI.AccountsGetAccounts, {
        ...params,
        clientSyncId: generateClientSyncId(),
    });
    return res.data;
};

export const updatePermissions = async (
    params: AccountPermissionsUpdatePermissionRequest,
) => {
    const res = await execute<
        AccountPermissionsUpdatePermissionRequest,
        AccountPermissionsResponse
    >(AccountsAPI.AccountPermissionsUpdatePermission, {
        ...params,
    });
    return res.data;
};

export const getEmployees = async (
    params: AccountsGetEmployeeAccountsRequest,
) => {
    const res = await execute<
        AccountsGetEmployeeAccountsRequest,
        AccountsGetEmployeeAccountsResponse
    >(AccountsAPI.AccountsGetEmployeeAccounts, {
        ...params,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getTechnicalAccounts = async (
    params: AccountsGetTechnicalSupportAccountsRequest,
) => {
    const res = await execute<
        AccountsGetTechnicalSupportAccountsRequest,
        AccountsGetTechnicalSupportAccountsResponse
    >(AccountsAPI.AccountsGetTechnicalSupportAccounts, { ...params });
    return res.data;
};
