import { execute } from '../../apiCommandsExecutor';
import { CrmAPI } from './methods';
import {
    SupportRequestLogsGetLogsRequest,
    SupportRequestLogsGetLogsResponse,
    SupportRequestsCreateFromPreSupportRequestRequest,
    SupportRequestsCreateFromPreSupportRequestResponse,
    SupportRequestsCreateSupportRequestRequest,
    SupportRequestsCreateSupportRequestResponse,
    SupportRequestsDeleteSupportRequestSystemAttachmentRequest,
    SupportRequestsGetByFlatIdRequest,
    SupportRequestsGetByFlatIdResponse,
    SupportRequestsGetDeleteSupportRequestSystemAttachmentResponse,
    SupportRequestsGetSupportRequestByViewableFlatsRequest,
    SupportRequestsGetSupportRequestByViewableFlatsResponse,
    SupportRequestsGetSupportRequestListRequest,
    SupportRequestsGetSupportRequestListResponse,
    SupportRequestsGetSupportRequestRequest,
    SupportRequestsGetSupportRequestResponse,
    SupportRequestsGetSupportRequestsStatisticsRequest,
    SupportRequestsGetSupportRequestsStatisticsResponse,
    SupportRequestsRemoveExecutorFromSupportRequestRequest,
    SupportRequestsRemoveExecutorFromSupportRequestResponse,
    SupportRequestsRemoveLinkedPreSupportRequestRequest,
    SupportRequestsRemoveLinkedPreSupportRequestResponse,
    SupportRequestsUpdateFeedbackRequest,
    SupportRequestsUpdateFeedbackResponse,
    SupportRequestsUpdateSupportRequestRequest,
    SupportRequestsUpdateSupportRequestResponse,
    SupportRequestsUpdateSupportRequestStatusRequest,
    SupportRequestsUpdateSupportRequestStatusResponse,
} from './requestsResponses';

export const createSupportRequest = async (
    params: SupportRequestsCreateSupportRequestRequest,
) => {
    const res = await execute<
        SupportRequestsCreateSupportRequestRequest,
        SupportRequestsCreateSupportRequestResponse
    >(CrmAPI.SupportRequestsCreateSupportRequest, params);

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const removeExecutor = async (
    params: SupportRequestsRemoveExecutorFromSupportRequestRequest,
) => {
    const res = await execute<
        SupportRequestsRemoveExecutorFromSupportRequestRequest,
        SupportRequestsRemoveExecutorFromSupportRequestResponse
    >(CrmAPI.SupportRequestsRemoveExecutorFromSupportRequest, { ...params });
    return res.data;
};

export const createSupportRequestFromPreRequest = async (
    params: SupportRequestsCreateFromPreSupportRequestRequest,
) => {
    const res = await execute<
        SupportRequestsCreateFromPreSupportRequestRequest,
        SupportRequestsCreateFromPreSupportRequestResponse
    >(CrmAPI.SupportRequestsCreateFromPreSupportRequest, params);

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const removeLinkedPreSupportRequest = async (
    params: SupportRequestsRemoveLinkedPreSupportRequestRequest,
) => {
    const res = await execute<
        SupportRequestsRemoveLinkedPreSupportRequestRequest,
        SupportRequestsRemoveLinkedPreSupportRequestResponse
    >(CrmAPI.SupportRequestsRemoveLinkedPreSupportRequest, params);
    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getSupportRequest = async (
    params: SupportRequestsGetSupportRequestRequest,
) => {
    const res = await execute<
        SupportRequestsGetSupportRequestRequest,
        SupportRequestsGetSupportRequestResponse
    >(CrmAPI.SupportRequestsGetSupportRequest, params);

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getViewableSupportRequests = async () => {
    const res = await execute<
        SupportRequestsGetSupportRequestByViewableFlatsRequest,
        SupportRequestsGetSupportRequestByViewableFlatsResponse
    >(CrmAPI.SupportRequestsGetSupportRequestByViewableFlats, {});

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getSupportRequestsWithFilters = async (
    params: SupportRequestsGetSupportRequestListRequest,
) => {
    const res = await execute<
        SupportRequestsGetSupportRequestListRequest,
        SupportRequestsGetSupportRequestListResponse
    >(CrmAPI.SupportRequestsGetSupportRequestList, params);

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateSupportRequestFeedback = async (
    params: SupportRequestsUpdateFeedbackRequest,
) => {
    const res = await execute<
        SupportRequestsUpdateFeedbackRequest,
        SupportRequestsUpdateFeedbackResponse
    >(CrmAPI.SupportRequestsUpdateFeedback, params);

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const updateSupportRequest = async (
    params: SupportRequestsUpdateSupportRequestRequest,
) => {
    const res = await execute<
        SupportRequestsUpdateSupportRequestRequest,
        SupportRequestsUpdateSupportRequestResponse
    >(CrmAPI.SupportRequestsUpdateSupportRequest, params);

    return res;
};

export const updateSupportRequestStatus = async (
    params: SupportRequestsUpdateSupportRequestStatusRequest,
) => {
    const res = await execute<
        SupportRequestsUpdateSupportRequestStatusRequest,
        SupportRequestsUpdateSupportRequestStatusResponse
    >(CrmAPI.SupportRequestsUpdateSupportRequestStatus, params);

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const deleteSupportRequestSystemAttachment = async (
    params: SupportRequestsDeleteSupportRequestSystemAttachmentRequest,
) => {
    const res = await execute<
        SupportRequestsDeleteSupportRequestSystemAttachmentRequest,
        SupportRequestsGetDeleteSupportRequestSystemAttachmentResponse
    >(CrmAPI.SupportRequestsDeleteSupportRequestSystemAttachment, params);

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getLogs = async (params: SupportRequestLogsGetLogsRequest) => {
    const res = await execute<
        SupportRequestLogsGetLogsRequest,
        SupportRequestLogsGetLogsResponse
    >(CrmAPI.SupportRequestLogsGetLogs, params);

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getRequestsByFlatId = async (
    params: SupportRequestsGetByFlatIdRequest,
) => {
    const res = await execute<
        SupportRequestsGetByFlatIdRequest,
        SupportRequestsGetByFlatIdResponse
    >(CrmAPI.SupportRequestsGetByFlatId, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getRequestsStatistics = async (
    params: SupportRequestsGetSupportRequestsStatisticsRequest,
) => {
    const res = await execute<
        SupportRequestsGetSupportRequestsStatisticsRequest,
        SupportRequestsGetSupportRequestsStatisticsResponse
    >(CrmAPI.SupportRequestsGetSupportRequestsStatistics, { ...params });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};
