export enum AccountsAPI {
    AccountsGetEmployeeAccounts = 'Accounts.GetEmployeeAccounts',
    AccountsCreateAccount = 'Accounts.CreateAccount',
    AccountsGetAccounts = 'Accounts.GetAccounts',
    AccountsGetMe = 'Accounts.GetMe',
    AccountsRemoveAccount = 'Accounts.RemoveAccount',
    AccountsUpdateAccount = 'Accounts.UpdateAccount',
    AccountPermissionsUpdatePermission = 'AccountPermissions.UpdatePermission',
    AccountsGetTechnicalSupportAccounts = 'Accounts.GetTechnicalSupportAccounts',
}
