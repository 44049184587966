import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import React, { lazy } from 'react';
import { AccountRole } from '../../services/accounts/types';
import ConciergeLayout from '../../layouts/ConciergeLayout';
import { RouteInfoType, RouteType } from '../../types/types';

const SupportChatListPage = lazy(() => import('./index'));
const SupportCRMListPage = lazy(() => import('./crm'));

const techSupportRoutes = {
    id: 'TechSupport',
    name: 'TechSupport',
    path: '/tech-support',
    hide: true,
    icon: <FontAwesomeIcon icon={faUsers} size={'lg'} />,
    roles: [AccountRole.TechnicalSupport, AccountRole.TechnicalSupportManager],
    component: <ConciergeLayout />,
    routes: [
        {
            path: '/tech-support',
            redirect: '/tech-support/chat-list',
            exact: true,
            hide: true,
        },
        {
            path: '/tech-support/chat-list',
            name: 'Список чатов с техподдержкой',
            component: <SupportChatListPage />,
            type: RouteType.route,
            roles: [
                AccountRole.TechnicalSupport,
                AccountRole.TechnicalSupportManager,
            ],
        },
        {
            path: '/tech-support/crm',
            name: 'Список заявок CRM',
            component: <SupportCRMListPage />,
            type: RouteType.route,
            roles: [
                AccountRole.TechnicalSupport,
                AccountRole.TechnicalSupportManager,
            ],
        },
    ],
} as RouteInfoType;

export default techSupportRoutes as RouteInfoType;
