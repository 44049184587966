import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import {
    LinkProps,
    NavLink as RouterNavLink,
    useLocation,
} from 'react-router-dom';
import { RouteInfoType } from '../../../types/types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../../vendor/perfect-scrollbar.css';
import { spacing } from '@mui/system';
import logo from '../../../assets/wiseLogo.png';
import logoutIcon from './assets/logout.png';
import accountChangeIcon from './assets/accountChange.png';

import {
    Box as MuiBox,
    Chip,
    Drawer as MuiDrawer,
    DrawerClassKey,
    Grid,
    List as MuiList,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { config as router } from '../../../router/index';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';
import { ClassNameMap } from '@mui/styles';
import AccountChangeModal from '../../../modules/auth/components/accountChangeModal';
import { OrgBranchAvailableModules } from '../../../services/organizations/models';
import { formatAccountName } from '../../utils/accountsUtils';

const NavLink = React.forwardRef<
    LinkProps,
    PropsWithChildren<{ to: string; className: string }>
>((props) => {
    return (
        <RouterNavLink to={props.to} className={props.className}>
            {props.children}
        </RouterNavLink>
    );
});

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
    border-right: 0;

    > div {
        border-right: 0;
    }

,
background: linear-gradient(176.43 deg, #1f4377 9.7 %, #0e3369 95.29 %);

`;

const Scrollbar = styled(PerfectScrollbar)`
    background: linear-gradient(176.43deg, #1f4377 9.7%, #0e3369 95.29%);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
    //background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div``;

const Brand = styled(ListItemButton)`
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.sidebar.header.color};
    font-family: ${(props) => props.theme.typography.fontFamily};
    min-height: 56px;
    padding-left: ${(props) => props.theme.spacing(3)};
    padding-right: ${(props) => props.theme.spacing(6)};
    background: #1f4377;

    ${(props) => props.theme.breakpoints.up('sm')} {
        min-height: 64px;
    }

    &:hover {
        background: #1f4377;
    }

    //border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

type CategoryType = {
    activeClassName?: string;
    button?: boolean;
    onClick?: () => void;
    to?: string;
    component?: typeof NavLink;
    exact?: boolean;
};

const Category = styled(ListItemButton)<CategoryType>`
    padding-top: ${(props) => props.theme.spacing(2)};
    padding-bottom: ${(props) => props.theme.spacing(2)};
    padding-left: ${(props) => props.theme.spacing(3)};
    padding-right: ${(props) => props.theme.spacing(2)};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    font-size: 14px;

    img {
        font-size: 15px;
        width: 20px;
        height: 20px;
        opacity: 0.6;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.05);

        span {
            color: rgba(255, 255, 255, 1);
        }

        img {
            opacity: 1;
        }
    }

    &.${(props) => props.activeClassName} {
        background-color: rgba(255, 255, 255, 0.1);

        span {
            color: rgba(255, 255, 255, 1);
        }

        img {
            opacity: 1;
        }
    }
`;

const CategoryText = styled(ListItemText)`
    margin: 0;

    span {
        color: rgba(255, 255, 255, 0.6);
        font-size: ${(props) => props.theme.typography.body1.fontSize};
        font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
        padding: 0 ${(props) => props.theme.spacing(3)};
    }
`;

const CategoryIconLess = styled(ExpandLess)`
    color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
    color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const LinkBadge = styled(Chip)`
    font-size: 11px;
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    height: 20px;
    position: absolute;
    right: 12px;
    top: 8px;
    //background: ${(props) => props.theme.sidebar.badge.background};

    span.MuiChip-label,
    span.MuiChip-label:hover {
        cursor: pointer;
        color: ${(props) => props.theme.sidebar.badge.color};
        padding-left: ${(props) => props.theme.spacing(2)};
        padding-right: ${(props) => props.theme.spacing(2)};
    }
`;

const CategoryBadge = styled(LinkBadge)`
    top: 12px;
`;

const SidebarSection = styled(Typography)`
    color: rgba(255, 255, 255, 0.9);
    padding: ${(props) => props.theme.spacing(4)}
        ${(props) => props.theme.spacing(3)}
        ${(props) => props.theme.spacing(1)};
    opacity: 1;
    display: block;
    font-weight: 500;
`;

type SidebarCategoryPropsType = {
    name: string;
    icon: JSX.Element;
    classes?: string;
    isOpen?: boolean;
    isCollapsable: boolean;
    badge?: string | number;
    activeClassName?: string;
    button: true;
    onClick?: () => void;
    to?: string;
    component?: typeof NavLink;
    exact?: boolean;
};

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
    name,
    icon,
    isOpen,
    isCollapsable,
    badge,
    to,
    activeClassName,
    component,
}) => {
    return (
        <Category
            component={component}
            to={to}
            activeClassName={activeClassName}
        >
            {icon}
            <CategoryText>{name}</CategoryText>
            {isCollapsable ? (
                isOpen ? (
                    <CategoryIconMore />
                ) : (
                    <CategoryIconLess />
                )
            ) : null}
            {badge ? <CategoryBadge label={badge} /> : ''}
        </Category>
    );
};

const SidebarFooter = styled.div`
    background-color: #0e3369;
    padding: ${(props) => props.theme.spacing(2.75)}
        ${(props) => props.theme.spacing(4)};
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled.div`
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
`;

const SidebarFooterSubText = styled.div`
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.725rem;
    display: block;
    padding: 1px;
`;

const LogoutButton = styled.div`
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 100ms;
    margin-left: 5px;

    img {
        width: 20px;
        height: 20px;
        margin-right: 0px;
        opacity: 0.9;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 1);

        img {
            opacity: 1;
        }
    }
`;

type SidebarPropsType = {
    classes?: Partial<ClassNameMap<DrawerClassKey>>;
    PaperProps: {
        style: {
            width: number;
            borderRadius: string;
        };
    };
    variant?: 'permanent' | 'persistent' | 'temporary';
    open?: boolean;
    onClose?: () => void;
};
type tplotOptions = {
    [key: number]: boolean;
};

const Sidebar: React.FC<SidebarPropsType> = ({
    PaperProps,
    variant,
    onClose,
    open,
    classes,
}) => {
    const location = useLocation();
    const {
        authStore,
        systemObjectsStore,
        organizationBranchesStore,
        generalStore,
    } = useStores();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [openRoutes, setOpenRoutes] = useState<tplotOptions>();
    const [modules, setModules] = useState<OrgBranchAvailableModules>(null);

    const initOpenRoutes = (): tplotOptions => {
        /* Open collapse element that matches current url */
        const pathName = location.pathname;

        let _routes = {};

        routes.forEach((route: any, index) => {
            const isActive = pathName.indexOf(route.path) === 0;
            const isOpen = route.open;
            const isHome = route.containsHome && pathName === '/';

            _routes = Object.assign({}, _routes, {
                [index]: isActive || isOpen || isHome,
            });
        });

        return _routes;
    };

    useEffect(() => {
        if (!generalStore.currentOrganizationBranch) return;
        setModules(generalStore.currentOrganizationBranch.availableModules);
    }, [generalStore.currentOrganizationBranch]);

    useEffect(() => {
        setOpenRoutes(initOpenRoutes());
    }, []);

    if (!authStore.isAuthorized) {
        return null;
    }
    const { role } = authStore.currentUser;

    let routes = [];
    (router[1].routes as RouteInfoType[]).forEach((item: RouteInfoType) => {
        if ((item.roles || []).length > 0 && item.roles.indexOf(role) < 0) {
            return;
        }
        if (item.hide) {
            routes.push(...item.routes);
        } else {
            routes.push(item);
        }
    });
    routes = routes.filter((route: RouteInfoType) => {
        if (!route.roles || route.roles.length === 0) {
            return true;
        } else {
            return route.roles.indexOf(role) >= 0;
        }
    });

    const clean = () => {
        systemObjectsStore.resetData();
        organizationBranchesStore.resetData();
    };

    const getCategoryHeader = (categoryHeader: string) => {
        if (!authStore.isOfficeOrganization()) return categoryHeader;

        switch (categoryHeader) {
            case 'Работа с жителями':
                return 'Работа с собственниками';
            default:
                return categoryHeader;
        }
    };

    const getCategoryName = (categoryName: string) => {
        if (!authStore.isOfficeOrganization()) return categoryName;

        switch (categoryName) {
            case 'Чаты с жителями':
                return 'Чаты с собственниками';
            case 'Жилые Комплексы':
                return 'Группы зданий';
            default:
                return categoryName;
        }
    };

    return (
        <Drawer
            variant={variant || 'permanent'}
            PaperProps={PaperProps}
            onClose={onClose}
            open={open}
            classes={classes}
        >
            <Brand>
                <Box component='div' ml={1} mt={2}>
                    <img src={logo} alt='' style={{ height: 35 }} />
                </Box>
            </Brand>
            <AccountChangeModal />
            <Scrollbar>
                <List disablePadding>
                    <Items>
                        {routes.map((category: RouteInfoType, index) => {
                            if (category.hide) return null;
                            if (modules) {
                                if (
                                    category.childrenModule &&
                                    !category.childrenModule.some(
                                        (i) => modules[i],
                                    )
                                )
                                    return null;
                                if (
                                    category.module &&
                                    !modules[category.module]
                                )
                                    return null;
                            } else return;
                            return (
                                <React.Fragment key={index}>
                                    {category.header ? (
                                        <SidebarSection>
                                            {getCategoryHeader(category.header)}
                                        </SidebarSection>
                                    ) : null}

                                    {category.type !== 'section' && (
                                        <SidebarCategory
                                            isCollapsable={false}
                                            name={getCategoryName(
                                                category.name,
                                            )}
                                            to={category.path}
                                            activeClassName='active'
                                            component={NavLink}
                                            icon={category.icon}
                                            exact
                                            button
                                            badge={category.badge}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Items>
                </List>
            </Scrollbar>
            <SidebarFooter>
                <Grid container spacing={2} justifyContent='space-between'>
                    <Grid item>
                        <SidebarFooterText>
                            {authStore.currentUser &&
                                formatAccountName(authStore.currentUser.data)}
                        </SidebarFooterText>
                        <SidebarFooterSubText>
                            {authStore.currentUser?.data.email}
                        </SidebarFooterSubText>
                    </Grid>
                    <Grid item>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <LogoutButton
                                onClick={() => {
                                    authStore.openAccountChangeModal();
                                }}
                            >
                                <img src={accountChangeIcon} alt='' />
                            </LogoutButton>
                            <LogoutButton
                                onClick={() => {
                                    clean();
                                    authStore.logout();
                                }}
                            >
                                <img src={logoutIcon} alt='' />
                            </LogoutButton>
                        </div>
                    </Grid>
                </Grid>
            </SidebarFooter>
        </Drawer>
    );
};

export default observer(Sidebar);
