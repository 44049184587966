import { action, makeAutoObservable } from 'mobx';
import {
    addMessageToChat,
    getChatMessages,
    readChatMessages,
} from '../../../../services/chat';
import {
    ChatMessageAttachment,
    ChatMessageClient,
} from '../../../../services/chat/models';
import moment from 'moment/moment';
// import { AccountRole } from '../../../../services/accounts/types';
// import axios from 'axios';
// import { OPEN_AI_KEY } from '../../../../apiCommandsExecutor/token';
// import { cases } from '../../../../gpt/cases';

export class ChatStore {
    isOpen = false;
    chatId: number;
    chatTitle: string;
    chatMessages: ChatMessageClient[] = [];
    attachments: ChatMessageAttachment[] = [];
    links: { link: string; fileName: string; fileId: string }[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async getChatMessages(chatId: number) {
        const response = await getChatMessages({
            limit: 100,
            skip: 0,
            chatId: chatId,
        });
        this.setMessages(response.messages.reverse());
    }

    async updateChatMessages(chatId: number) {
        const response = await getChatMessages({
            limit: 100,
            skip: 0,
            chatId: chatId,
        });
        if (this.chatMessages.length > 0) {
            if (
                this.chatMessages[this.chatMessages.length - 1].createdAt ===
                response.messages[0].createdAt
            ) {
                return;
            } else {
                this.setMessages(response.messages.reverse());
                // const latestMessage =
                //     response.messages[response.messages.length - 1];
                // if (latestMessage.accountRole === AccountRole.HouseResident) {
                //     await this.handleNewMessage(latestMessage.content);
                // }
            }
        } else this.setMessages(response.messages.reverse());
    }

    // async getEmbeddings(phrases: string[]): Promise<number[][]> {
    //     try {
    //         const response = await axios.post(
    //             'https://api.openai.com/v1/embeddings',
    //             {
    //                 model: 'text-embedding-ada-002',
    //                 input: phrases,
    //             },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${OPEN_AI_KEY}`,
    //                     'Content-Type': 'application/json',
    //                 },
    //             },
    //         );
    //         return response.data.data.map((item: any) => item['embedding']);
    //     } catch (error) {
    //         console.error('Ошибка при получении встраиваний:', error.message);
    //         return [];
    //     }
    // }
    //
    // async findBestMatchingCase(userMessage: string): Promise<string | null> {
    //     const userEmbedding = await this.getEmbeddings([userMessage]);
    //
    //     let bestMatchScore = 0;
    //     let bestResponse = null;
    //     const similarityThreshold = 0.8;
    //
    //     const allTriggerPhrases = cases.flatMap((caseItem) =>
    //         caseItem.triggers.flatMap((trigger) => trigger.phrases),
    //     );
    //     const triggerEmbeddings = await this.getEmbeddings(allTriggerPhrases);
    //     for (const caseItem of cases) {
    //         for (const trigger of caseItem.triggers) {
    //             for (const phrase of trigger.phrases) {
    //                 const index = allTriggerPhrases.indexOf(phrase);
    //                 const similarity = this.cosineSimilarity(
    //                     userEmbedding[0],
    //                     triggerEmbeddings[index],
    //                 );
    //                 if (
    //                     similarity > bestMatchScore &&
    //                     similarity >= similarityThreshold
    //                 ) {
    //                     bestMatchScore = similarity;
    //                     bestResponse = caseItem.response[trigger.type];
    //                 }
    //             }
    //         }
    //     }
    //     return bestResponse;
    // }
    //
    // async handleNewMessage(content: string) {
    //     try {
    //         const caseResponse = await this.findBestMatchingCase(content);
    //
    //         if (caseResponse) {
    //             await this.addMessageToChat(caseResponse);
    //         } else {
    //             const response = await axios.post(
    //                 'https://api.openai.com/v1/chat/completions',
    //                 {
    //                     model: 'gpt-3.5-turbo',
    //                     messages: [{ role: 'user', content: content }],
    //                 },
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${OPEN_AI_KEY}`,
    //                         'Content-Type': 'application/json',
    //                     },
    //                 },
    //             );
    //
    //             const botReply = response?.data?.choices?.[0]?.message?.content;
    //             if (!botReply) {
    //                 throw new Error('Ответ от OpenAI не содержит сообщения');
    //             }
    //             await this.addMessageToChat(botReply);
    //         }
    //     } catch (error) {
    //         console.error(
    //             'Ошибка обработки нового сообщения:',
    //             error.response?.data || error.message,
    //         );
    //     }
    // }
    //
    // @action
    // cosineSimilarity(vec1: number[], vec2: number[]): number {
    //     if (vec1.length === 0 || vec2.length === 0) {
    //         return 0;
    //     }
    //     const dotProduct = vec1.reduce(
    //         (acc, _, index) => acc + vec1[index] * vec2[index],
    //         0,
    //     );
    //     const magnitudeVec1 = Math.sqrt(
    //         vec1.reduce((acc, val) => acc + val * val, 0),
    //     );
    //     const magnitudeVec2 = Math.sqrt(
    //         vec2.reduce((acc, val) => acc + val * val, 0),
    //     );
    //
    //     if (magnitudeVec1 === 0 || magnitudeVec2 === 0) {
    //         return 0;
    //     }
    //     return dotProduct / (magnitudeVec1 * magnitudeVec2);
    // }

    async addMessageToChat(content: string) {
        const response = await addMessageToChat({
            chatId: this.chatId,
            content: content,
            attachments: this.attachments,
        });
        return response === null;
    }

    async readChatMessages() {
        const response = await readChatMessages({
            chatId: this.chatId,
            readingTime: moment().toDate(),
        });
        return response === null;
    }

    @action
    openModal(chatId: number, title: string) {
        this.isOpen = true;
        this.setChat(chatId, title);
    }

    @action
    closeModal() {
        this.setChat(undefined, undefined);
        this.chatMessages = [];
        this.attachments = [];
        this.links = [];
        this.isOpen = false;
    }

    @action
    setChat(chatId: number, chatTitle: string) {
        this.chatId = chatId;
        this.chatTitle = chatTitle;
    }

    @action
    setMessages(messages: ChatMessageClient[]) {
        this.chatMessages = messages;
    }

    @action
    setAttachments(attachments: ChatMessageAttachment[]) {
        this.attachments = attachments;
    }

    @action
    setLinks(links) {
        this.links = links;
    }

    @action
    resetStore() {
        this.chatId = undefined;
        this.chatTitle = '';
        this.chatMessages = [];
    }

    @action
    removeAttachment(name?: string) {
        if (!name) {
            return;
        }
        this.attachments = this.attachments.filter(
            (attachment) => attachment.name !== name,
        );
    }

    @action
    removeLink(name?: string) {
        if (!name) {
            return;
        }
        this.links = this.links.filter((link) => link.fileName !== name);
    }
}

const chatStore = new ChatStore();

export default chatStore;
